import React, {useRef, useState} from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import LoginService from "../service/LoginService";
import {Toast} from "primereact/toast";
import {useHistory} from "react-router-dom";
const loginService = new LoginService(); // Create an instance of LoginService
export const Login = (props) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const toast = useRef(null);
	const history = useHistory();
	const handleLogin = async () => {
		try {
			const data = { email, password };
			setLoading(true)
			const userData = await loginService.login(data); // Call the login method
			setLoading(false)
			if(userData.status === 200){
				toast.current.show({ severity: 'success', summary: 'Login efetuado com sucesso!', detail: 'redirecionando...', life: 3000 });
				localStorage.setItem('token', userData.data.token);
				localStorage.setItem('user', JSON.stringify(userData.data.user));
				localStorage.setItem('nomeLoja', userData.data.nomeWl);
				setTimeout(() => {
					history.push('/');
				},1000)
			}

			console.log('User data after login:', userData);
			// Handle successful login, such as updating state or navigating to another page
		} catch (error) {
			// Handle login error, such as displaying an error message to the user
			setLoading(false)
			toast.current.show({severity: 'error', summary: 'Error', detail: 'Erro ao fazer login'});
			console.error('Login error:', error);
		}
	};

	return (
		<div className="login-body">
			<Toast ref={toast} />
			<div style={{width:'30%'}} className="login-image">

			</div>
			<div className="login-panel p-fluid">
				<div className="flex flex-column">
					<div className="flex align-items-center mb-6 logo-container">
						<h1>ValoriPay</h1>
					</div>
					<div className="form-container">
						<span className="p-input-icon-left">
							<i className="pi pi-envelope"></i>
							<InputText  value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Email" />
						</span>
						<span className="p-input-icon-left">
							<i className="pi pi-key"></i>
							<InputText value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
						</span>
						<button className="flex p-link">Forgot your password?</button>
					</div>
					<div className="button-container">
						<Button loading={loading} onClick={handleLogin} type="button" label="Login"></Button>

					</div>
				</div>

				<div className="login-footer flex align-items-center">
					<div className="flex align-items-center login-footer-logo-container">
						<h4>ValoriPay</h4>
					</div>
					<span>Copyright 2021</span>
				</div>
			</div>
		</div>
	)
}
