import React, {useEffect, useState} from 'react';
import RoleService from "./service/RoleService";
import {Dropdown} from "primereact/dropdown";


const AppFooter = (props) => {

    useEffect(() => {

    }, []);

    return (
        <div className="layout-footer">
            <span className="copyright">&#169; Valori - 2024</span>
        </div>

    )
}

export default AppFooter;
