import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { classNames } from 'primereact/utils';
import {useHistory} from "react-router-dom";

const AppInlineMenu = (props) => {
    const history = useHistory();
    const isSlim = () => {
        return props.menuMode === 'slim';
    }

    const isStatic = () => {
        return props.menuMode === 'static';
    }

    const isSidebar = () => {
        return props.menuMode === 'sidebar';
    }

    const isMobile = () => {
        return window.innerWidth <= 991;
    }

    const handleLogout = () => {
        // logout logic
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('nomeLoja');
        localStorage.removeItem('lojaSelecionada');
        history.push('/login');

    }

    return (
        <>
            {!isMobile() && (isStatic() || isSlim() || isSidebar()) && <div className={classNames('layout-inline-menu', { 'layout-inline-menu-active': props.activeInlineProfile })}>
                <button className="layout-inline-menu-action p-link" onClick={props.onChangeActiveInlineMenu}>

                    <i className="layout-inline-menu-icon pi pi-angle-down"></i>
                </button>
                <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={props.activeInlineProfile} unmountOnExit>
                    <ul className="layout-inline-menu-action-panel">
                        <li className="layout-inline-menu-action-item">
                            <button onClick={handleLogout} className="p-link">
                                <i className="pi pi-power-off pi-fw"></i>
                                <span>Logout</span>
                            </button>
                        </li>
                    </ul>
                </CSSTransition>
            </div>}
        </>
    )
}

export default AppInlineMenu;
