import axiosInstance from "../axios/axiosInstance";


export default class LoginService {

    login(data) {
        return axiosInstance.post('login',data)
    }

}
