import axiosInstance from "../axios/axiosInstance";


export default class AlertaService {

    getAlertas() {
        return axiosInstance.get('api/alerta')
    }

    getTiposAlerta() {
        return axiosInstance.get('api/alerta/tipos')
    }

    getPeriodos() {
        return axiosInstance.get('api/alerta/periodos')
    }

    cadastrar(data) {

        return axiosInstance.post('api/alerta', data)
    }

    editar(data,id) {

        return axiosInstance.put('api/alerta/'+id, data)
    }
    deleteAlerta(id) {
        return axiosInstance.delete('api/alerta/' + id)
    }

    getAlertaById(id) {
        return axiosInstance.get('api/alerta/' + id)
    }

}
