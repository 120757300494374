import React, {useState, useEffect, useRef} from 'react';
import {classNames} from 'primereact/utils';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Dropdown} from 'primereact/dropdown';
import {InputNumber} from 'primereact/inputnumber';
import {Button} from 'primereact/button';
import {ProgressBar} from 'primereact/progressbar';
import {Calendar} from 'primereact/calendar';
import {MultiSelect} from 'primereact/multiselect';
import {Slider} from 'primereact/slider';
import {TriStateCheckbox} from 'primereact/tristatecheckbox';
import {ToggleButton} from 'primereact/togglebutton';
import {Rating} from 'primereact/rating';
import {useHistory} from "react-router-dom";
import {Checkbox} from "primereact/checkbox";
import {Dialog} from "primereact/dialog";
import {showToast} from "../ToastManager";
import NotificacaoService from "../../service/NotificacaoService";
import {Image} from "primereact/image";
import {FileUpload} from "primereact/fileupload";
import RoleService from "../../service/RoleService";

const notificacaoService = new NotificacaoService(); // Create an instance of NotificacaoService
const roleService = new RoleService();
const WlListarNotificacao = () => {
    const [notificacoes, setNotificacoes] = useState([]);
    const [filtredNotificacoes, setFiltredNotificacoes] = useState([]);
    const [parceladasNotificacoes, setParceladasNotificacoes] = useState([]);
    const [filters1, setFilters1] = useState(null);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const history = useHistory();
    const [deleteNotificacaoDialog, setDeleteNotificacaoDialog] = useState(false);
    const [notificacaoParaDeletar, setNotificacaoParaDeletar] = useState(null);
    const fileUploadRef = useRef(null);

    useEffect(() => {
        initFilters1();
        initNotificacoes();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const initNotificacoes = () => {
        setLoading1(true);
        notificacaoService.getNotificacoesNaoBloqueadas().then(data => {
            setNotificacoes(data.data);

            // Filtrando notificações
            setFiltredNotificacoes(data.data.filter(notificacao => notificacao.captureChannel != 'DIGITAL' && notificacao.acquirerNSU != null
                && notificacao.whiteLabel != 'valori' && notificacao.whiteLabel != null && notificacao.parcels < 13));
            setLoading1(false);
        });
    }

    const initFilters1 = () => {
        setFilters1({
            'alerta.nome': {
                operator: FilterOperator.AND,
                constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
            },
            'dataFormatada': {
                operator: FilterOperator.AND,
                constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
            },
            'dataPagamentoFormatada': {
                operator: FilterOperator.AND,
                constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
            },
            'valor': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
            'acquirerNSU': {
                operator: FilterOperator.AND,
                constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
            },
            'cnpj': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
            'merchant': {
                operator: FilterOperator.AND,
                constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
            },
            'descricao': {
                operator: FilterOperator.AND,
                constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
            },
            'captureChannel': {
                operator: FilterOperator.AND,
                constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
            },
            'statusText': {
                operator: FilterOperator.AND,
                constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
            },
            'whiteLabel': {
                operator: FilterOperator.AND,
                constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]
            },
        });
    }

    const precoBody = (rowData) => {
        return <span>{formatCurrency(rowData.valor / 100)}</span>
    }

    const cnpjBody = (rowData) => {
        return <span>{formatCnpj(rowData.cnpj)}</span>
    }

    const formatCurrency = (value) => {
        if (value != null) {
            return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
        } else {
            return "";
        }
    }

    const formatCnpj = (value) => {
        if (value != null) {
            return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
        } else {
            return "";
        }
    }

    const bloqueiaNotificacao = (notificacao) => {
        setLoading1(true);
        notificacaoService.bloqueiaNotificacao(notificacao.id).then(() => {
            showToast({severity: 'success', summary: 'Sucesso', detail: 'Notificação bloqueada com sucesso'});
            setLoading1(false);
            initNotificacoes();
        });
    }

    const opcoesBody = (rowData) => {
        return <span style={{display: 'flex', justifyContent: 'space-between'}}>
            <Button loading={loading1} icon="pi pi-ban" onClick={() => {
                bloqueiaNotificacao(rowData)
            }} style={{backgroundColor: 'red', borderColor: 'red'}} className="p-button-rounded mr-2 mb-2"/>
        </span>
    }

    return (
        <div className="grid table-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Outras notificações - White Label</h5>
                    <DataTable value={filtredNotificacoes} paginator className="p-datatable-gridlines" showGridlines
                               rows={10}
                               dataKey="id" filters={filters1} filterDisplay="menu" loading={loading1}
                               responsiveLayout="scroll"
                               emptyMessage="Nenhuma notificação digital cadastrada.">
                        <Column sortable field="alerta.nome" header="Nome" filter filterPlaceholder="Procurar por nome"
                                style={{minWidth: '12rem'}}/>
                        <Column sortable field="dataFormatada" header="Data da notificação" filter
                                filterPlaceholder="Procurar por data" style={{minWidth: '12rem'}}/>
                        <Column sortable field="merchant" header="Codigo EC" filter
                                filterPlaceholder="Procurar por codigo" style={{minWidth: '12rem'}}/>
                        <Column sortable field="descricao" header="Nome Fantasia" filter
                                filterPlaceholder="Procurar por Nome fantasia" style={{minWidth: '12rem'}}/>
                        <Column sortable field="whiteLabel" header="White Label" filter
                                filterPlaceholder="Procurar por White Label" style={{minWidth: '12rem'}}/>
                        <Column sortable field='cnpj' body={cnpjBody} header='CNPJ/CPF' filter
                                filterPlaceholder='Procurar por CNPJ/CPF' style={{minWidht: '12rem'}}/>
                        <Column sortable field="acquirerNSU" header="NSU" filter filterPlaceholder="Procurar por NSU"
                                style={{minWidth: '12rem'}}/>
                        <Column sortable field="valor" body={precoBody} header="Valor" filter
                                filterPlaceholder="Procurar por valor" style={{minWidth: '12rem'}}/>
                        <Column sortable field="statusText" header="Status" filter
                                filterPlaceholder="Procurar por status" style={{minWidth: '12rem'}}/>
                        <Column sortable field="parcels" header="Parcelas" filter
                                filterPlaceholder="Procurar por parcela" style={{minWidth: '12rem'}}/>
                        <Column sortable field="captureChannel" header="Meio de Captura" filter
                                filterPlaceholder="Procurar por valor" style={{minWidth: '12rem'}}/>
                        {/* Outras colunas */}
                        <Column sortable body={opcoesBody} header="Ações" style={{minWidth: '12rem'}}/>
                    </DataTable>
                </div>
            </div>
        </div>
    );
}


const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(WlListarNotificacao, comparisonFn);
