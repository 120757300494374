import React, {useState, useEffect, useRef} from 'react';
import { classNames } from 'primereact/utils';
import { Image } from 'primereact/image';

import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';

import AlertaService from "../../service/AlertaService";
import {useHistory, useParams} from "react-router-dom";
import {InputText} from "primereact/inputtext";

import {AutoComplete} from "primereact/autocomplete";
import {showToast} from "../ToastManager";

import {Checkbox} from "primereact/checkbox";
import {Dropdown} from "primereact/dropdown";

const alertaService = new AlertaService(); // Create an instance of AlertaService

const Dashboard = () => {
    const history = useHistory();


    const { id } = useParams();
    useEffect(() => {

    }, []); // eslint-disable-line react-hooks/exhaustive-deps










    return (
        <div className="grid">
            <h4>Dashboard</h4>
        </div>

    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Dashboard, comparisonFn);
