import axiosInstance from "../axios/axiosInstance";


export default class NotificacaoService {

    getNotificacoes() {
        return axiosInstance.get('api/notificacao')
    }

    getNotificacoesNaoBloqueadas() {
        return axiosInstance.get('api/notificacao/nao-bloqueadas')
    }

    getNotificacoesBloqueadas() {
        return axiosInstance.get('api/notificacao/bloqueadas')
    }

    bloqueiaNotificacao(id) {
        return axiosInstance.post('api/notificacao/bloquear', {id: id})
    }

    desbloqueiaNotificacao(id) {
        return axiosInstance.post('api/notificacao/desbloquear', {id: id})
    }

    getNumeroNaoVisualizadas() {
        return axiosInstance.get('api/notificacao/count/nao-visualizadas')
    }

    getNotificacoesNaoVisualizadas() {
        return axiosInstance.get('api/notificacao/nao-visualizadas')
    }

    visualizarNotificacao(id) {
        return axiosInstance.get('api/notificacao/visualiza')
    }

}
