import React from 'react';
import { Toast } from 'primereact/toast';

// Create a ref for the Toast component
export const toastRef = React.createRef();

// Function to show toast messages
export const showToast = (message) => {
    toastRef.current.show(message);
};

// Export the Toast component with the ref
export const ToastManager = () => <Toast ref={toastRef} />;
