import React from 'react';
import { useHistory } from 'react-router-dom';
import AppMenu from './AppMenu';
import { classNames } from 'primereact/utils';

const AppTopbar = (props) => {

    const onTopbarSubItemClick = (event) => {
        event.preventDefault();
    }

    const history = useHistory();

    return (
        <>
            <div className="layout-topbar">
                <div className="layout-topbar-left">
                    <button className="topbar-menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-bars"></i>
                    </button>


                    <button className="p-link" onClick={() => history.push('/')}>
                        <h5 style={{color: props.colorScheme === 'light' ? 'black' : 'white',marginLeft:20}}>{localStorage.getItem('nomeLoja')}</h5>
                    </button>
                </div>

                <AppMenu model={props.items} menuMode={props.menuMode} colorScheme={props.colorScheme} menuActive={props.menuActive} activeInlineProfile={props.activeInlineProfile}
                    onSidebarMouseOver={props.onSidebarMouseOver} onSidebarMouseLeave={props.onSidebarMouseLeave} toggleMenu={props.onToggleMenu} onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}
                    onMenuClick={props.onMenuClick} onRootMenuItemClick={props.onRootMenuItemClick} onMenuItemClick={props.onMenuItemClick} />

                <div className="layout-topbar-right">

                </div>
            </div>
        </>
    )
}

export default AppTopbar;
